import React from 'react';
import Navbar from '../ui/Navbar';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-content">
        <h1>Bienvenidos a Dodo Press</h1>
        <p>¡QUE PERREEN LOS GATOS Y LAS GATAS!</p>
      </div>
    </div>
  );
}

export default Home;
